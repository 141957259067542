import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client"
import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
  RouteProp,
} from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { CachePersistor } from "apollo3-cache-persist"

import React, { createRef, useEffect, useState } from "react"
import { View } from "react-native"
import {
  ActivityIndicator,
  IconButton,
  Provider as PaperProvider,
} from "react-native-paper"
import { RecoilRoot } from "recoil"
import { createApolloAsync } from "./src/apollo"
import { GameDescription } from "./src/pages/GameDescription"
import { Home } from "./src/pages/Home"
import { SettingScreen } from "./src/pages/SettingScreen"

export type HomeStackParamList = {
  Home: undefined
  GameDescription: { id: string }
  Setting: undefined
}
export type GameDescriptionRouteProp = RouteProp<
  HomeStackParamList,
  "GameDescription"
>

const HomeStack = createStackNavigator<HomeStackParamList>()

const linking: LinkingOptions = {
  prefixes: ["localhost:8080"],
  config: {
    screens: {
      Home: {
        path: "",
      },
      GameDescription: {
        path: "description/:id",
      },
      Setting: {
        path: "setting",
      },
    },
  },
}

export default function App() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  const [persistor, setPersistor] = useState<
    CachePersistor<NormalizedCacheObject>
  >()
  const navigationRef = createRef<NavigationContainerRef>()

  useEffect(() => {
    createApolloAsync().then(({ apollo, persistor }) => {
      setClient(apollo)
      setPersistor(persistor)
    })
  }, [])

  if (!client) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <PaperProvider>
          <NavigationContainer linking={linking} ref={navigationRef}>
            <HomeStack.Navigator initialRouteName="Home">
              <HomeStack.Screen
                name="Home"
                component={Home}
                options={{
                  title: "ゲーム発売日",
                  headerRight: () => (
                    <IconButton
                      icon="cog"
                      onPress={() => navigationRef.current?.navigate("Setting")}
                    />
                  ),
                }}
              />
              <HomeStack.Screen
                name="GameDescription"
                component={GameDescription}
              />
              <HomeStack.Screen
                name="Setting"
                component={SettingScreen}
                options={{ title: "設定" }}
              />
            </HomeStack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </ApolloProvider>
    </RecoilRoot>
  )
}
