import { gql, useQuery } from "@apollo/client"
import { Link } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import React, { useCallback } from "react"
import { Linking, ScrollView } from "react-native"
import { ActivityIndicator, Checkbox, List, Text } from "react-native-paper"
import { useExcludeHardware } from "../hooks/useExcludeHardware"
import { FetchHardwares } from "./__generated__/FetchHardwares"

export const FETCH_HARDWARE = gql`
  query FetchHardwares {
    genres(where: { parent_id: { _is_null: true } }) {
      id
      name
    }
  }
`

export const SettingScreen: React.FC<{
  navigation: StackNavigationProp<any>
}> = ({ navigation }) => {
  const { loading, error, data } = useQuery<FetchHardwares>(FETCH_HARDWARE)
  const [value, setValue] = useExcludeHardware()

  const addOrRemove = function <T>(arr: T[], item: T) {
    return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]
  }

  const toggleId = useCallback(
    (id: string) => {
      setValue(addOrRemove(value, id))
    },
    [value]
  )

  const goLink = useCallback((url: string) => {
    Linking.canOpenURL(url).then((bool) => {
      if (bool) {
        Linking.openURL(url)
      }
    })
  }, [])

  return (
    <ScrollView>
      <List.Section>
        <List.Subheader>ハードウェア</List.Subheader>
        {!loading || <ActivityIndicator />}
        {data?.genres.map((h) => (
          <Checkbox.Item
            key={h.id}
            label={h.name}
            status={value.includes(h.id) ? "unchecked" : "checked"}
            onPress={() => toggleId(h.id)}
          />
        ))}
      </List.Section>
      <List.Section>
        <List.Item
          title="プライバシーポリシー"
          onPress={() => goLink("https://gamecal.mk2481.dev/privacy-policy")}
        />
        <List.Item
          title="利用規約"
          onPress={() => goLink("https://gamecal.mk2481.dev/team-conditions")}
        />
      </List.Section>
    </ScrollView>
  )
}
