import React from "react"
import { FetchHome_games } from "../pages/__generated__/FetchHome"
import { GameListItemOnPress } from "./GameListItem"
import { GameListSection } from "./GameListSection"

export const GameList: React.FC<{
  games: FetchHome_games[]
  onPress: GameListItemOnPress
}> = ({ games, onPress }) => {
  const _games: { [key: string]: FetchHome_games[] } = games.reduce((r, a) => {
    r[a.sales_date] = r[a.sales_date] || []
    r[a.sales_date].push(a)
    return r
  }, Object.create(null))

  return (
    <>
      {Object.entries(_games).map(([key, _games]) => (
        <GameListSection
          key={key}
          date={key}
          games={_games}
          onPress={onPress}
        />
      ))}
    </>
  )
}
