import { ApolloClient, InMemoryCache } from "@apollo/client"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { AsyncStorageWrapper, CachePersistor } from "apollo3-cache-persist"

export const createApolloAsync = async () => {
  const cache = new InMemoryCache()
  const persistor = new CachePersistor({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
    debug: __DEV__,
    trigger: "write",
  })
  await persistor.restore()
  const apollo = new ApolloClient({
    uri: "https://gamecal-hasura.herokuapp.com/v1/graphql",
    cache,
  })
  return { apollo, persistor }
}
