import { gql, useQuery } from "@apollo/client"
import { StackNavigationProp } from "@react-navigation/stack"
import React, { useCallback, useEffect } from "react"
import { Image, Linking, ScrollView, View } from "react-native"
import { ActivityIndicator, FAB, List, Text } from "react-native-paper"
import HTML from "react-native-render-html"
import { GameDescriptionRouteProp } from "../../App"
import { FetchGameById } from "./__generated__/FetchGameById"

const FETCH_GAME_BY_ID = gql`
  query FetchGameById($id: String!) {
    games_by_pk(jan: $id) {
      jan
      title
      sales_date
      large_image_url
      item_caption
      affiliate_url
    }
  }
`

export const GameDescription: React.FC<{
  navigation: StackNavigationProp<any>
  route: GameDescriptionRouteProp
}> = ({ navigation, route }) => {
  const { id } = route.params
  const { loading, error, data } = useQuery<FetchGameById>(FETCH_GAME_BY_ID, {
    variables: { id },
  })
  useEffect(() => {
    const title = data?.games_by_pk?.title
    if (title) {
      navigation.setOptions({ title })
    }
  }, [data?.games_by_pk?.jan])
  const goStore = useCallback(() => {
    const url = data?.games_by_pk?.affiliate_url
    if (url) {
      Linking.canOpenURL(url).then((bool) => {
        if (bool) {
          Linking.openURL(url)
        }
      })
    }
  }, [data?.games_by_pk?.affiliate_url])
  if (loading || !data) {
    return <ActivityIndicator style={{ padding: 8 }} animating={true} />
  }
  if (error) {
    console.error(error)
    return <Text>error</Text>
  }
  return (
    <>
      <ScrollView>
        <List.Section style={{ alignItems: "center" }}>
          <Image
            style={{ width: 256, height: 256 }}
            resizeMode="contain"
            source={{ uri: data.games_by_pk?.large_image_url! }}
          />
        </List.Section>
        <List.Section>
          <List.Subheader>説明文</List.Subheader>
          <View style={{ marginLeft: 16, marginRight: 16 }}>
            <HTML source={{ html: data.games_by_pk?.item_caption! }} />
          </View>
        </List.Section>
      </ScrollView>
      <FAB
        style={{ position: "absolute", margin: 16, right: 0, bottom: 0 }}
        icon="plus"
        label="go store"
        onPress={goStore}
      />
    </>
  )
}
