import { useAsyncStorage } from "@react-native-async-storage/async-storage"
import { useCallback, useEffect } from "react"
import { useRecoilState } from "recoil"
import { ExcludeHardwareAtom } from "../atoms/ExcludeHardwareAtom"

export const useExcludeHardware = (): [string[], (ids: string[]) => void] => {
  const [state, setState] = useRecoilState(ExcludeHardwareAtom)
  const { getItem, setItem } = useAsyncStorage("EXCLUDE_HARDWARE")
  useEffect(() => {
    getItem((error, result) => {
      setState(JSON.parse(result || "[]"))
    }).then((result) => setState(JSON.parse(result || "[]")))
  }, [])
  const _setItem = useCallback(
    (ids: string[]) => {
      setState(ids)
      setItem(JSON.stringify(ids))
    },
    [state]
  )
  return [state, _setItem]
}
