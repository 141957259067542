import React, { useCallback } from "react"
import { Image } from "react-native"
import { List } from "react-native-paper"
import { FetchHome_games } from "../pages/__generated__/FetchHome"

export type GameListItemOnPress = (game: FetchHome_games) => void

export const GameListItem: React.FC<{
  game: FetchHome_games
  onPress: GameListItemOnPress
}> = ({ game, onPress }) => {
  const _onPress = useCallback(() => {
    onPress(game)
  }, [game])
  return (
    <List.Item
      key={game.jan}
      title={game.title}
      description={game.hardware?.name}
      titleNumberOfLines={2}
      onPress={_onPress}
      left={(props) => (
        <Image
          {...props}
          key={game.jan}
          style={{ width: 72, height: 72 }}
          resizeMode="contain"
          source={{ uri: game.large_image_url!! }}
        />
      )}
    />
  )
}
