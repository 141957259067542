import React from "react"
import { List } from "react-native-paper"
import { FetchHome_games } from "../pages/__generated__/FetchHome"
import { GameListItem, GameListItemOnPress } from "./GameListItem"

export const GameListSection: React.FC<{
  date: string
  games: FetchHome_games[]
  onPress: GameListItemOnPress
}> = ({ date, games, onPress }) => (
  <List.Section key={date}>
    <List.Subheader>{date}</List.Subheader>
    {games.map((game) => (
      <GameListItem key={game.jan} game={game} onPress={onPress} />
    ))}
  </List.Section>
)
