import { gql, useQuery } from "@apollo/client"
import { StackNavigationProp } from "@react-navigation/stack"
import dayjs from "dayjs"
import React, { useCallback } from "react"
import { ScrollView } from "react-native"
import { ActivityIndicator, Text } from "react-native-paper"
import { GameList } from "../components/GameList"
import { useExcludeHardware } from "../hooks/useExcludeHardware"
import { FetchHardwares } from "./__generated__/FetchHardwares"
import { FetchHome, FetchHome_games } from "./__generated__/FetchHome"
import { FETCH_HARDWARE } from "./SettingScreen"

const FETCH_HOME = gql`
  query FetchHome($salesDate: date, $hardwareIds: [String!]) {
    games(
      order_by: { sales_date: asc }
      where: {
        sales_date: { _gte: $salesDate }
        hardware_id: { _in: $hardwareIds }
      }
    ) {
      hardware {
        id
        name
      }
      jan
      title
      sales_date
      large_image_url
    }
  }
`

export const Home: React.FC<{ navigation: StackNavigationProp<any> }> = ({
  navigation,
}) => {
  const hardwareResult = useQuery<FetchHardwares>(FETCH_HARDWARE)
  const [excludeHardwareIds] = useExcludeHardware()
  const salesDate = dayjs().format("YYYY-M-D")
  const gamesResult = useQuery<FetchHome>(FETCH_HOME, {
    skip: !hardwareResult.data,
    variables: {
      salesDate,
      hardwareIds: hardwareResult.data?.genres
        .filter((h) => !excludeHardwareIds.includes(h.id))
        .map((h) => h.id),
    },
  })

  const onPress = useCallback((game: FetchHome_games) => {
    navigation.navigate("GameDescription", { id: game.jan })
  }, [])

  if (hardwareResult.loading || gamesResult.loading || !gamesResult.data) {
    return <ActivityIndicator style={{ padding: 8 }} animating={true} />
  }

  if (hardwareResult.error || gamesResult.error) {
    console.error(hardwareResult.error)
    console.error(gamesResult.error)
    return <Text>error</Text>
  }

  return (
    <ScrollView>
      <GameList games={gamesResult.data?.games} onPress={onPress} />
    </ScrollView>
  )
}
